import { API_URL, API_KEY, API_VERSION } from "@/configs/api";
import axios from "axios";

const instance = axios.create({
  baseURL: `${API_URL}/${API_VERSION}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Api_key: API_KEY,
  },
  responseType: "json",
  timeout: 100000,
});

async function processRefreshRequest(originalRequest: any) {}

function needRefresh(error: any) {
  const statusCode = error?.response?.data?.statusCode;
  // const message = error?.response?.data?.message;
  return statusCode === 401;
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (needRefresh(error)) {
      return await processRefreshRequest(error);
    }

    return Promise.reject(error);
  }
);

export { instance };
