import {_get, _post} from "@/services/utils";
import { endpoint } from "@/services/endpoint";

export const volunteersApi = {
    async getVolunteers(params:VolunteersParams) {
        return _get(endpoint.volunteers,params);
    },

    async createVolunteers(payload:Volunteers) {
        return _post(endpoint.volunteers,payload);
    },
};
