import { instance } from "@/libs/axios";
import { ApiResponse } from "@/types/api/response";

type Obj = { [key: string]: any };

const querySearch = (params: Obj = {}): string => {
  return Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
};

function _get<T, R = ApiResponse<T>>(
  route: string,
  params?: Obj,
  config?: Obj
): Promise<R> {
  return instance.get(
    params && Object.keys(params).length > 0
      ? `${route}?${querySearch(params)}`
      : route,
    config
  );
}
function _post<T, R = ApiResponse<T>>(
  route: string,
  payload?: Obj,
  config?: Obj
): Promise<R> {
  return instance.post(route, payload, config);
}
function _put<T, R = ApiResponse<T>>(
  route: string,
  payload?: Obj,
  config?: Obj
): Promise<R> {
  return instance.put(route, payload, config);
}

function _patch<T, R = ApiResponse<T>>(
  route: string,
  payload?: Obj,
  config?: Obj
): Promise<R> {
  return instance.patch(route, payload, config);
}

function _delete<T, R = ApiResponse<T>>(
  route: string,
  config?: Obj
): Promise<R> {
  return instance.delete(route, config);
}

function _upload<T, R = ApiResponse<T>>(
  route: string,
  payload: Obj
): Promise<R> {
  const formData = new FormData();

  function appendFormData(nameInput: string, array: Array<any>): void {
    for (let value of array) {
      formData.append(nameInput, value);
    }
  }

  const keysData = Object.keys(payload);

  if (keysData.length > 0) {
    for (let keyItem of keysData) {
      if (Array.isArray(payload[keyItem])) {
        appendFormData(keyItem, payload[keyItem]);
      } else {
        formData.append(keyItem, payload[keyItem]);
      }
    }
  }

  return instance.post(route, formData);
}

export { _delete, _get, _patch, _post, _put, _upload };
