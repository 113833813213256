"use client";

import { useCallback, useEffect } from "react";
import { services } from "@/services";
import { useAppConfigsStore } from "@/stores/useAppConfigsStore";
import { APP_RUN_MODE } from "@/enum";

export default function BackgroundDataPrefetch() {
  const setSelectedProvince = useAppConfigsStore(
    (state) => state.setSelectedProvince
  );
  const setAppConfig = useAppConfigsStore((state) => state.setAppConfig);

  const fetchAppConfig = useCallback(async () => {
    try {
      const res: any = await services.configsApi.getConfigs();

      if (res.data) {
        setAppConfig(res.data.provinces, res.data.requestTypes);
        if (
          process.env.NEXT_PUBLIC_RUN_MODE === APP_RUN_MODE.PROVINCE &&
          process.env.NEXT_PUBLIC_PROVINCE_DEFAULT === "VP"
        ) {
          setSelectedProvince(
            res.data?.provinces.find((item: any) => item?.code === "VP")
          );
        }
      }
    } catch (error) {}
  }, [setAppConfig, setSelectedProvince]);

  useEffect(() => {
    fetchAppConfig();
  }, [fetchAppConfig]);

  return null;
}
